.OneGame {
	margin: 20px 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 4px;
}
.OneGame > div {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	height: 475px;
	/* background: linear-gradient(rgba(88, 88, 88, 0.45), rgba(58, 58, 58, 0.5)); */
	background: linear-gradient(
		150deg,
		rgba(121, 121, 121, 0.45),
		rgba(19, 19, 19, 0.4)
	);
	/* padding: 15px; */
	box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
	position: relative;
	border-radius: 4px;
}
.OneGame > div > div:first-child {
	height: -webkit-fill-available;
	display: flex;
	align-items: center;
	justify-content: center;
}
.OneGame h2 {
	font-weight: bold;
	font-size: 40px;
	padding: 20px;
	text-align: center;
	/* margin-top: 85px; */
}
