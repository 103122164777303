.SliderTeams {
  width: 100%;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 10px;
  position: relative;
  padding: 0px 20px;
  margin-bottom: 20px;
}
.SliderTeamsDevice {
  padding: 0px !important;
}
.SliderTeamsDevice > div > div {
  overflow-x: scroll !important;
  overflow-y: hidden;
  padding: 0 15px;
}
.SliderTeams > div {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.SliderTeams > div > div {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: visible;
  width: 100%;
  height: fit-content;
  transition: transform 0.5s ease;
}


/* INDIVIDUAL */

.SliderTeams .oneTeam {
  display: inline-block;
  position: relative;
}
.SliderTeamsDevice .oneTeam {
  width: unset !important;
  display: inline-block;
  width: fit-content !important;
}
.SliderTeamsDevice .oneTeam > div {
  padding: 0 20px;
  width: fit-content !important;
}
.SliderTeams .oneTeam > div {
  /* display: inline-block; */
  margin: 0 5px;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 21px;
  border-radius: 4px;
  user-select: none;
  /* position: relative; */
  padding: 20px;
  transition: 0.5s background ease, 0.3s border ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}
.SliderTeamsDevice .oneTeam > div {
  position: relative;
  width: 100%;
}

.SliderTeams .teamSelected > div {
  background-color: rgba(255, 255, 255, 0.3);
  /* border: 1px solid rgba(125, 125, 125, 0.5); */
}
.oneTeam:hover > div {
  border: 1px solid rgba(183, 183, 183, 0.5);
}