/* LOADING */
.OnePlayerLoading > .OnePlayerInfo {
  transform: translateY(0px);
  height: 360px;
  /* background-color: #263238 !important; */
}
.OnePlayerLoading p, .OnePlayerLoading h4 {
  /* color: #eceff1 !important; */
}
.OnePlayerLoading > img {
  transform: translateY(-170px) !important;
}

/* LOADED */

.OnePlayer {
  max-width: 260px;
  /* width: 200px; */
  /* display: inline-block; */
  flex: 1 0 200px;
  margin: 10px;
  vertical-align: top;
  border-radius: 4px;
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;
  transition: 0.5s transform ease;
  height: 425px;
  background: linear-gradient(rgba(121, 121, 121, 0.45), rgba(19, 19, 19, 0.5));
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
}
.OnePlayer:hover {
  transform: translateY(-10px);
}
.OnePlayer:hover > img {
  background-color: #cecece;
}
.OnePlayer:hover > .OnePlayerInfo{
  transform: translateY(275px);
}
.OnePlayer > img {
  border-radius: 4px 4px 0 0;
  position: relative;
  transform: translateY(-170px);
  transition: 0.5s background ease, 0.5s transform ease;
  width: 100%;
}
.OnePlayerInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  transform: translateY(255px);
  padding: 20px;
  padding-bottom: 10px;
  background-color: #cecece;
  height: 170px;
  transition: 0.5s transform ease, 0.5s height ease, 0.5s background ease, 0.5s color ease;
  border-radius: 4px;
}
.OnePlayerInfo > div > h4 {
  font-size: 30px;
  font-weight: bold;
  color: #171717;
}
.OnePlayerInfo > div > p {
  font-size: 16px;
  color: #555555;
  margin-top: 5px;
}
.OnePlayerSocial {
  color: #171717;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* margin-top: 35px; */
  /* right: 12px; */
  /* position: absolute; */
}
.OnePlayerSocial a {
  padding: 5px;
}
.OnePlayerSocial img {
  height: 16px;
  color: #171717;
  margin-left: 4px;
}

