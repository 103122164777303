#contact {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 10px; */
}
#contact h1 {
  margin-left: 10px;
  margin-top: 20px;
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: bold;
}
#contact > div {
  display: flex;
}

/* FORM */

.contactForm {
  flex: 1 1 33%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px;
  background-color: aliceblue;
  min-height: fit-content;
  border-radius: 4px;
  color: #ffffffaf;
  padding: 30px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
}
.contactForm p {
  margin-bottom: 20px;
}

.contactForm h2 {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}

.contactTeam {
  flex: 1;
  background-color: #2b2b2b;
 }
.contactStaff {
  flex: 2;
  background-color: #2b2b2b;
}
/* LOAD & SUCCESS */
.contactSuccess {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.contactSuccess > p {
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 0 !important;
}
/* LAYOUT */
.contactTwo {
  display: flex;
  flex-direction: row;
}
.contactTwo > :first-child {
  margin-right: 10px;
}
/* INPUT */
.contactFormInput {
  padding: 10px;
  background-color: #ffffff0A;
  color: white;
  border: 1px solid #ffffff08;
  width: 100%;
  border-radius: 4px;
  height: 50px;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s color ease;
}
#contact textarea {
  font-family: Roboto, sans-serif;
}
#contact option {
  /* color: black; */
  line-height: 30px;
  background-color: #202020;
  height: 50px;
}

.contactFormInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.70);
  opacity: 1; /* Firefox */
}

.contactFormInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.70);
}

.contactFormInput::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.70);
}

/* SELECTION */
.contactSelection {
  user-select: none;
  background-color: #ffffff0A;
  border: 1px solid #ffffff08;
  border-radius: 4px;
  color: white;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s background ease, 0.3s color ease;
}
.contactSelection:hover {
  background-color: #ffffff41;
}
.contactSelected {
  background-color: #ffffff60;
}
.contactSelected:hover {
  background-color: #ffffff60 !important;
}

/* SUBMIT */
.contactSubmit {
  
}
.contactSubmitDisabled {
  cursor: initial !important;
  color: #ffffff60 !important;
}

@media (max-width: 750px) {
  #contact > div {
    flex-direction: column;
    margin: 20px;
  }
  .contactForm {
    height: fit-content;
  }
  #contact h1 {
    margin-left: 30px;
  }
}

@media (max-width: 425px) {
  .contactForm {
    padding: 20px;
  }
  #contact > div {
    margin: 10px;
  }
}