footer {
  width: 100%;
  padding: 30px;
  margin-top: 70px;
}
footer > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerLogo {
  margin-bottom: 45px;
}
.footerSocial > a {
  margin: 0 20px;
}
.footerLegal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  color: #bdbdbd;
}
.footerLegal > div > a {
  color: #bdbdbd;
  margin-left: 25px;
}

@media (max-width: 375px) {
  .footerSocial > a {
    margin: 0 12px;
  }
}
@media (max-width: 610px) {
  .footerLegal {
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    line-height: 20px;
  }
  .footerLegal > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footerLegal > div > a {
    margin: 0;
    display: inline-block;
    margin-top: 10px;
  }
}