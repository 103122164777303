#team {
  max-width: 1200px;
  margin: 0 auto;
}

/* HEADER */
.teamHeader {
  width: 100%;
  border-radius: 4px;
  /* height: 500px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: -5px -5px 15px #00000047; */
  /* overflow: hidden; */
}
.teamHeader > div {
  border-radius: 4px;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#171717 5%, rgba(121, 121, 121, 0.45) 40%, #171717 90%);
  background: linear-gradient(rgba(121, 121, 121, 0.45) 40%, rgba(45, 44, 44, 0.9) 70%, #171717 100%);

}
.teamHeader h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

/* BODY */
.teamBody {
  transform: translateY(-150px);
  margin-bottom: -120px;
}
/* TEAM */
.teamBodyTeam {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 5px;
}

/* STAFF */
.teamBodyStaff {
  background-color: #2b2b2b;
  border-radius: 4px;
  margin: 0 15px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  flex-flow: wrap;
}
.teamBodyStaff > div {
  min-width: 300px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
}
.teamBodyStaff > div > :first-child {
  height: 75px;
  width: 75px;
  background-color: rgb(177, 177, 177);
  border-radius: 38px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.teamBodyStaff > div > :last-child {
  width: 50%;
}
.teamBodyStaff h4 {
  font-weight: bold;
  font-size: 30px;
}
.teamBodyStaff p {
  color: rgb(168, 168, 168);
}

@media (max-width: 425px) {
  .teamBodyStaff {
    border-radius: 0px;
    margin: 0;
    box-shadow: none;
  }
}