header {
	padding: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	transition: 0.5s box-shadow ease;
}
header > a {
	margin: 0 40px;
	font-size: 18px;
	width: 10%;
	text-align: center;
	max-width: 100px;
}
header > a > img {
	height: 80px;
}
header button {
	background-color: white;
	border: 0;
	cursor: pointer;
}
.headerScroll {
	box-shadow: 0 0 7px rgb(0, 0, 0);
}
.headerMobileMenu {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	color: white;
	justify-content: center;
}

@media (min-width: 500px) and (max-width: 600px) {
	header {
		padding: 0 30px !important;
	}
}
@media (max-width: 600px) {
	header {
		position: fixed;
		flex-direction: column;
		top: 0;
		background-color: #171717;
		width: 100%;
		padding: 0 20px;
	}
	header > div:first-child {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 15px 0;
	}
	header a {
		margin: 0;
	}
	header a > img {
		height: 40px;
	}
	header button {
		background: transparent;
		padding: 0;
	}
	header button > img {
		height: 22px;
	}
	.headerMobileMenu a {
		margin: 0;
		width: 100%;
		text-align: center;
		font-size: 30px;
		padding: 30px 0;
		font-weight: bold;
	}
}
