/* GENERAL */
#home {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  /* background-color: brown; */
}
.homeTwo {
  display: flex;
  margin-bottom: 70px;
}
.homeTwo > :first-child {
  flex: 2 2;
  /* background-color: rgb(230, 195, 150); */
  /* height: 500px; */
}
.homeTwo > :last-child {
  /* flex: 1 1; */
  width: 400px;
  /* height: 500px; */
  /* background-color: antiquewhite; */
}
#home h2 {
  font-size: 50px;
  font-weight: bold;
  color: white;
}
#home p {
  font-size: 17px;
  color: rgb(200, 200, 200);
}

/* ACTUALIDAD */
.actualidadText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homeTwitterWrapper {
  max-height: 550px;
  height: 550px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #212121;
  margin-left: 15px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
}

/* STREAM */

.streamText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 800px) {
  #home h2 {
    font-size: 45px;
  }
  .homeTwo {
    flex-direction: column;
    align-items: center;
  }
  .actualidadText, .streamText {
    margin-bottom: 50px;
  }
  .streamText, .homeTwitterWrapper, .homeTwitchWrapper {
    width: 100% !important;
    margin-left: 0;
  }
  .homeTwitch {
    flex-direction: column-reverse !important;
  }
}