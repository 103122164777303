.OneTeam {
  width: 100%;
  border-radius: 3px;
  background-color: #2b2b2b;
  padding: 20px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.28);
}

/* HEADER */

.OneTeamHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.OneTeamHeader > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.OneTeamLink {
  background-color: rgb(226, 226, 226);
  color: #171717;
  display: inline-block;
  margin-left: 30px;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
}

.OneTeamHeader h3 {
  font-weight: bold;
  font-size: 35px;
  margin-left: 10px;
}
.OneTeamHeader button {
  border: none;
  background: none;
  cursor: pointer;
}
.OneTeamHeader button > img {height: 15px}


/* BODY */

.OneTeamBody {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

@media (max-width: 500px) {
  .OneTeamHeader > div {
    flex-direction: column;
    align-items: flex-start;
  }
  .OneTeamHeader h3 { margin-left: 0; }
  .OneTeamLink { margin-left: 0; margin-top: 10px}
  /* .OneTeamBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
}