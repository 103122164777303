#teams {
  /* background-color: #607d8b; */
  padding: 10px;
  margin: 0 auto;
  max-width: 1200px;
}

#teams h1 {
  font-weight: bold;
  font-size: 50px;
}

#teams .subtitle {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  color: rgb(206, 206, 206);
  margin-top: 10px;
  margin-bottom: 60px;
}
.subtitle > h2 {
  margin-right: 20px;
}

@media (max-width: 1250px) {
  #teams {
    margin: 0 20px;
  }
}
@media (max-width: 500px) {
  #teams {
    margin: 10px;
  }
}