a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font:inherit;vertical-align:baseline;box-sizing:border-box;-webkit-tap-highlight-color:transparent}a,button,input,textarea{outline:none}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1;font-family:Arial,sans-serif;box-sizing:border-box}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

body{background-color:#171717;color:white;font-family:'Roboto',sans-serif;}a{text-decoration: none;color:white;}
@media(max-width:600px){#root{margin-top:90px;}}

#background{position: fixed;z-index:5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:rgba(23, 23, 23, 0.5);}
.backgroundOpen{display: block;}
.backgroundClosed{display: none;}